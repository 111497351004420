import request from '@/utils/request'


// 查询用户点赞记录列表
export function listLike(query) {
  return request({
    url: '/user/userLike/list',
    method: 'get',
    params: query
  })
}

// 查询用户点赞记录分页
export function pageLike(query) {
  return request({
    url: '/user/userLike/page',
    method: 'get',
    params: query
  })
}

// 查询用户点赞记录详细
export function getLike(data) {
  return request({
    url: '/user/userLike/detail',
    method: 'get',
    params: data
  })
}

// 新增用户点赞记录
export function addLike(data) {
  return request({
    url: '/user/userLike/add',
    method: 'post',
    data: data
  })
}

// 修改用户点赞记录
export function updateLike(data) {
  return request({
    url: '/user/userLike/edit',
    method: 'post',
    data: data
  })
}

// 删除用户点赞记录
export function delLike(data) {
  return request({
    url: '/user/userLike/delete',
    method: 'post',
    data: data
  })
}
